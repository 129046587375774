import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import PoppinsLight from "theme/fonts/Poppins/Poppins-Light.ttf";
import PoppinsMedium from "theme/fonts/Poppins/Poppins-Medium.ttf";
import PoppinsRegular from "theme/fonts/Poppins/Poppins-Regular.ttf";
import PoppinsSemiBold from "theme/fonts/Poppins/Poppins-SemiBold.ttf";
import PoppinsBold from "theme/fonts/Poppins/Poppins-Bold.ttf";

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  @font-face {
    font-family: "Poppins-Light";
    src: url(${PoppinsLight}) format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url(${PoppinsRegular}) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: "Poppins-Medium";
    src: url(${PoppinsMedium}) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: "Poppins-SemiBold";
    src: url(${PoppinsSemiBold}) format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: "Poppins-Bold";
    src: url(${PoppinsBold}) format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: auto;
  }
  h1 {
    font-family: 'Poppins-Regular';
  }
  body {
    font-family: "Poppins-Regular";
    padding: 0;
    color: #fff;
    background-color: #000;
  }
`;
