import styled from "styled-components";

const Section = styled.section`
  color: #fff;
  background-color: #000;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: ${({ size }) => (size === "medium" ? 66 : 33)}vh;
`;

export default Section;
