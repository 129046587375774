import { css } from "styled-components";

import Section from "components/Section";
import Box from "components/Box";
import { Logo } from "icons/logo";
// import AuthButton from "components/AuthButton";

function Home() {
  return (
    <Box
      sx={css`
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
      `}
    >
      <Box as="header">
        <Box
          sx={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 16px;
          `}
        >
          <Box
            sx={css`
              display: flex;
              align-items: center;
            `}
          >
            <Logo />
            <Box
              as="span"
              sx={css`
                margin-left: 6px;
                font-size: 1.2rem;
                font-family: "Poppins-Medium";
              `}
            >
              sandLabs
            </Box>
          </Box>
          {/* <AuthButton /> */}
        </Box>
      </Box>
      <Box as="main" sx={css``}>
        <Section size="medium">
          <Box
            sx={css`
              padding-bottom: 13.2vmax;
              padding-top: 3vw;
              justify-content: center;
              padding-right: 3vw;
              padding-left: 3vw;
              margin: 0 auto;
              box-sizing: content-box;
            `}
          >
            <Box>
              <Box
                as="h1"
                sx={css`
                  text-align: center;
                  @media screen and (min-width: 0) and (max-width: 2013px) and (orientation: landscape) {
                    font-size: calc((4 - 1) * 1.2vw + 1rem);
                  }
                  @media screen and (min-width: 0) and (max-width: 2013px) and (orientation: portrait) {
                    font-size: calc((4 - 1) * 1.2vh + 1rem);
                  }
                  @media screen and (min-width: 2014px) {
                    font-size: calc(4 * 1rem);
                  }
                `}
              >
                sandLabs is a{" "}
                <Box
                  as="span"
                  sx={css`
                    color: #eb61fb;
                  `}
                >
                  Decentralized Autonomous Lab{" "}
                </Box>
                <Box as="span" sx={css``}>
                  for web3 economies
                </Box>
              </Box>
            </Box>
          </Box>
        </Section>
      </Box>
      <Box
        as="footer"
        sx={css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;
        `}
      >
        <Box>© sandLabs (SLAB)</Box>
        <Box>
          <Box
            as="a"
            href="https://t.me/sandlabsDAO"
            target="_blank"
            sx={css`
              color: inherit;
              text-decoration: underline;
              &:hover,
              &:active,
              &:focus {
                text-decoration: underline;
              }
            `}
          >
            Telegram
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
