import { GlobalStyle } from "./theme/GlobalStyle";
import { Helmet } from "react-helmet";
import Home from "screens/Home";

function App() {
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <title>sandLabs</title>
        <meta
          name="description"
          content="sandLabs is a Decentralized Autonomous Lab for web3 economies"
        />
        <link rel="canonical" href="https://sandlabs.xyz" />
      </Helmet>
      <Home />
    </>
  );
}

export default App;
