export function Logo({ color = "#eb61fb", size = 24 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 595.275 595.275"
    >
      <path
        fill={color}
        d="M582.42,397.184c4.668-12.276-11.264-21.327-19.617-11.188    c-41.371,50.214-103.428,64.437-159.795,63.61c-95.499-1.399-130.283-69.891-135.323-89.857    c53.177,47.161,117.843,67.211,188.194,49.189c111.247-28.495,148.441-119.228,128.482-192.511    C556.583,99.757,467.295,9.635,303.401,9.635c-137.679,0-253.525,90.46-287.521,213.309c-6.593,23.822-10.108,48.861-10.108,74.695    c0,7.349,0.602,36.494,3.499,41.001c0.73,1.138,2.165,1.943,3.445,1.514c1.545-0.519,2.071-2.397,2.366-4    c3.388-18.328,4.975-36.95,8.914-55.241c4.343-20.167,10.527-39.94,18.583-58.934c18.586-43.829,48.556-88.179,87.008-117.02    C243.477,9.635,385.421,54.424,438.721,94.786c79.371,60.098,102.781,178.368,33.732,238.274    c-65.205,56.569-217.466-2.774-160.029-94.929c4.848-7.78-0.682-18.002-9.845-17.731c-66.969,1.985-107.652,54.297-112.675,130.134    c-2.65,40.032,9.684,106.349,73.685,156.315c2.493,1.945,0.508,6.018-2.516,5.095c-55.188-16.842-126.032-86.685-130.694-173.699    c-5.557-103.681,63.494-157.443,116.67-169.729c77.051-17.805,156.482,20.403,165.148,98.788c0.65,5.874,8.521,7.292,11.393,2.128    c29.502-53.097,15.057-125.702-65.428-164.472c-73.405-35.357-195.146-9.733-255.164,73.924    c-53.51,74.586-72.621,160.13-27.381,266.115c19.179,44.933,90.478,140.643,227.785,140.643    C431.584,585.641,540.615,507.17,582.42,397.184z"
      ></path>
    </svg>
  );
}
